/**
 * Salta
 *
 * @author Robuust
 * @author Gijs Stegehuis <gijs@robuust.digital>
 */

import { Controller } from '@hotwired/stimulus';

/**
 * Returns Controller.
 */
export default class extends Controller {
  static values = {
    totalQuantityText: String,
    locale: String,
    shippingCost: Number,
    orderQuantity: Number,
    orderShippingTotal: Number,
  };

  static targets = [
    'enabled',
    'amount',
    'shippingCosts',
    'totalQuantity',
    'totalPrice',
    'withTrampoline',
    'withoutTrampoline',
    'refundable',
    'submit',
  ];

  initialize() {
    this.formatter = new Intl.NumberFormat(this.localeValue, {
      style: 'currency',
      currency: 'EUR',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
  }

  /**
   * Toggle enabled.
   */
  toggleEnabled(e) {
    const checkbox = e.target;
    const index = this.enabledTargets.findIndex((el) => el === e.target);
    const amount = this.amountTargets[index];
    const hasTrampoline = this.enabledTargets.some((el) => el.checked && el.dataset.isTrampoline === 'true');

    if (checkbox.checked) {
      amount.value = amount.getAttribute('max');
      amount.disabled = false;
    } else {
      amount.value = 0;
      amount.disabled = true;
    }

    this.withTrampolineTargets.forEach((el) => {
      el.classList.toggle('hidden', !hasTrampoline);
    });
    this.withoutTrampolineTargets.forEach((el) => {
      el.classList.toggle('hidden', hasTrampoline);
    });
  }

  /**
   * Sum totals.
   */
  sum() {
    let totalQuantity = 0;
    let totalPrice = 0;
    let refundable = 0;

    this.enabledTargets
      .forEach((el, index) => {
        if (el.checked) {
          const amount = this.amountTargets[index];
          const quantity = parseInt(amount.value || 0, 10);
          let refundableLinePrice = amount.dataset.price;
          totalQuantity += quantity;
          if (el.dataset.isTrampoline === 'true') {
            refundableLinePrice -= this.shippingCostValue;
          }
          totalPrice += parseFloat(amount.dataset.price * quantity, 10);
          refundable += parseFloat(refundableLinePrice * quantity, 10);
        }
      });

    if (totalQuantity === this.orderQuantityValue) {
      this.shippingCostsTarget.classList.remove('hidden');
      totalPrice += this.orderShippingTotalValue;
    } else {
      this.shippingCostsTarget.classList.add('hidden');
    }

    this.totalQuantityTarget.innerHTML = this.totalQuantityTextValue.replace('{amount}', totalQuantity);
    this.totalPriceTarget.innerHTML = this.formatter.format(totalPrice);
    this.refundableTarget.innerHTML = this.formatter.format(refundable);
  }

  /**
   * Disable submit button.
   */
  disableSubmit() {
    this.submitTarget.disabled = true;
  }
}
